<template>
<v-layout>
    <v-container> 
            <div v-if="validatedToken">
                <v-card
                  color="primary"
                  tile
                >
                    <v-card-title class="layout justify-center friendly-text font-weight-black">Password Reset</v-card-title>
                    <v-card-subtitle class="layout justify-center friendly-text font-weight-bold">Please enter a new password for your account.</v-card-subtitle>
                    <v-form 
                    class="pa-4"
                    v-model="passwordValid"
                    >

                    <v-text-field 
                        type="password" 
                        label="New Password"
                        v-model="newPassword"
                        outlined
                        color="contrast"
                        :rules="[passwordRules.min, passwordRules.allNum, passwordRules.allLetters]"
                    >
                    </v-text-field>

                    <v-text-field 
                        type="password" 
                        label="Confirm password"
                        v-model="confirmedPassword"
                        outlined
                        color="contrast"
                        :rules="[passwordRules.min, passwordRules.allNum, passwordRules.allLetters]"
                        :error-messages="passwordErrors()"
                    >
                    </v-text-field>
                    <vue-recaptcha 
                      ref="invisibleRecaptcha"
                      sitekey="6LfHk8gbAAAAABabtN3PLTUvVV8WUHn9ipRVswGE" 
                      :loadRecaptchaScript="true"
                      @verify="onVerify"
                      @expired="onExpired"
                      size="invisible"
                      >
                    </vue-recaptcha>
                    <v-btn
                        @click="submit()"
                        :disabled="!passwordValid"
                        outlined
                        color="contrast"
                    >
                        Change Password
                    </v-btn>
                    </v-form>
                </v-card>
            </div>
            <div v-else>
            </div>
    </v-container>
</v-layout> 
</template>


<script>
import passwordResetService from '@/services/passwordResetService';
import VueRecaptcha from 'vue-recaptcha';
import reCaptchaService from '@/services/recaptchaService';

export default {
  name: "passwordreset",
  props: ["resetToken"],
  components: { VueRecaptcha },
  data: () => ({
      validatedToken: false,
      newPassword: '',
      confirmedPassword: '',
      passwordValid: false,
      passwordNote: "Please update your password.",
      passwordRules: {
          min: v => v.length >= 8 || 'Password must be 8 or more characters.',
          allNum: v => !(/^\d+$/.test(v)) || 'Password cannot be only numbers',
          allLetters: v => !(/^[A-z]+$/.test(v)) || 'Password cannot be only letters'
        },
  }),
  created() {
      this.tokenValidation()
  },

  methods:{
      
      clearPasswords(){
          this.newPassword = ''
          this.confirmedPassword = ''
      },

      async submitNewPassword(){
          let data = {
              "password": this.newPassword,
              "token": this.resetToken
          }
          const passwordStatus = await passwordResetService.submitPasswordReset(data)

        switch(passwordStatus.status) {
          case (500):
            this.displayNotification('authentication', 'Server Error', 'Something went wrong on our end.' + '\n' + 'Hang tight we are working on it.', 'error')
            this.clearPasswords()
            break;
          case (404):
            this.displayNotification('authentication', 'Invalid Token', 'It appears your token has expired.' + '\n' + 'Please submit a new password reset request.',  'error')
            this.clearPasswords()
            break;  
          case (400):
            this.displayNotification('authentication', 'Invalid Password Selected', passwordStatus.data.password,  'error')
            this.clearPasswords()
            break;  
          case (200):
            this.displayNotification('authentication', 'Password Reset Successful', `You have successfully reset your password.`, 'success')
            this.$router.push("/")
              break;
          default:
            this.displayNotification('authentication', 'Failure', passwordStatus.data.detail, 'error')
            this.clearPasswords()
            break;
            }

          return passwordStatus.status===200?true:false
      },

      async tokenValidation(){
         this.validatedToken =  await this.tokenCheck();
      },
      async tokenCheck() {
        let data = {"token": this.resetToken}
        const tokenStatus = await passwordResetService.checkResetToken(data);
        return tokenStatus.status===200?true:false
      },

      passwordErrors() {
      return (this.newPassword === this.confirmedPassword) ? '' : 'Passwords must match.'
    },

    displayNotification(group, title, text, type) {
      this.$notify({
                  group: group,
                  title: title,
                  text: text,
                  type: type
                })
    },
    submit: function () {
      this.$refs.invisibleRecaptcha.execute()
    },
    resetRecaptcha() {
      this.$refs.invisibleRecaptcha.reset() // Direct call reset method
    },
    onVerify: async function (response) {
      let data = {"response": response}
      let r = await reCaptchaService.checkReCaptcha(data);
      if(r.data.success==true){
        this.submitNewPassword()
        this.resetRecaptcha()
      }
      else{
        this.displayNotification('authentication', 'Reset failed', 'Our system has flagged you as a robot.' + '\n' + 'If you believe this is an error, please refresh your page and try again.',  'error')
        this.resetRecaptcha()
      }
    },
    onExpired: function () {
      console.log('Expired')
    },

  },

  watch:{
      confirmedPassword: function() {
        let min = v => v.length >= 8
        let allNum= v => !(/^\d+$/.test(v))
        let allLetters= v => !(/^[A-z]+$/.test(v))

        if(this.confirmedPassword !== this.newPassword) {
          this.passwordValid = false
        } 
        else if(!min(this.confirmedPassword) || !min(this.newPassword)){
          this.passwordValid = false
        }
        else if(!allNum(this.confirmedPassword) || !allNum(this.newPassword)){
          this.passwordValid = false
        }
        else if(!allLetters(this.confirmedPassword) || !allLetters(this.newPassword)){
          this.passwordValid = false
        }
        else {
          this.passwordValid = true
        }
      }
  }
};
</script>